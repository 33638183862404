import * as React from 'react';
import { StepperContainerMobile } from '@/components/stepper/mobile/StepperContainerMobile';
import { useTranslation } from '@/utils/translation/useTranslation';

interface Props {
  step: number;
  hideTitle: boolean;
}

const StepperMobile: React.FC<Props> = ({ step, hideTitle }) => {
  const { t } = useTranslation();

  return (
    <StepperContainerMobile
      hideTitle={hideTitle}
      steps={[
        t('checkout:stepper.step1'),
        t('checkout:stepper.step2'),
        t('checkout:stepper.step3'),
        t('checkout:stepper.step4'),
        t('checkout:stepper.step5')
      ]}
      step={step}
    />
  );
};

export { StepperMobile };
