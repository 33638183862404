import * as React from 'react';
import { cn } from '@/utils/utils';
import { Oval } from 'react-loading-icons';
import { Link } from '@/utils/translation/navigation';
import { UrlObject } from 'url';

enum LegacyButtonVariant {
  Contained = 'Contained',
  Outline = 'Outline',
  Yellow = 'Yellow',
  Purple = 'Purple'
}

enum LegacyButtonSize {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small'
}

enum LegacyIconPosition {
  InFront,
  Behind
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  buttonVariant?: LegacyButtonVariant;
  buttonSize?: LegacyButtonSize;
  buttonSizeMobile?: LegacyButtonSize;
  fullWidth?: boolean;
  className?: string;
  loading?: boolean;
  href?: string | UrlObject;
  externalHref?: string;
  hrefTarget?: '_blank' | '_self' | '_parent' | '_top';
  icon?: React.ReactNode;
  onClick?: (e: React.SyntheticEvent) => void;
  iconPosition?: LegacyIconPosition;
}

const LegacyButtonComponent: React.FC<Props> = ({ children, href, externalHref, ...rest }) => {
  if (externalHref != undefined) {
    return (
      <a
        className={cn(rest.className, 'select-none', { 'pointer-events-none': rest.disabled })}
        href={externalHref}
        target={rest.hrefTarget}
        onClick={rest.onClick}
      >
        {children}
      </a>
    );
  }
  if (href != undefined) {
    return (
      <Link
        className={cn(rest.className, 'select-none', { 'pointer-events-none': rest.disabled })}
        href={href}
        target={rest.hrefTarget}
        onClick={rest.onClick}
      >
        {children}
      </Link>
    );
  }

  return <button {...rest}>{children}</button>;
};

const LegacyButton: React.FC<Props> = ({
  children,
  buttonVariant = LegacyButtonVariant.Contained,
  buttonSize,
  buttonSizeMobile,
  fullWidth = false,
  className,
  loading = false,
  href,
  externalHref,
  icon,
  iconPosition = LegacyIconPosition.InFront,
  ...rest
}) => {
  return (
    <LegacyButtonComponent
      {...rest}
      href={href}
      externalHref={externalHref}
      className={cn(
        'rounded-lg',
        'text-common-white',
        'border-2',
        'border-solid',
        'focus:outline-4',
        'focus:outline',
        'focus:outline-system-focus',
        {
          'text-[1.25rem]': buttonSize == 'Large' || buttonSize == undefined,
          'text-[1rem]': buttonSize == 'Small' || buttonSize == 'Medium',
          'md:text-[1.25rem]': buttonSizeMobile == 'Large' || buttonSizeMobile == undefined,
          'md:text-[1rem]': buttonSizeMobile == 'Small' || buttonSizeMobile == 'Medium',
          'leading-[130%]': true
        },
        {
          'px-4': true,
          'py-[0.9375rem]': buttonSize == 'Large' || buttonSize == undefined,
          'py-2': buttonSize == 'Medium',
          'py-[0.375rem]': buttonSize == 'Small',
          'md:py-[0.9375rem]': buttonSizeMobile == undefined || buttonSizeMobile == 'Large',
          'md:py-2': buttonSizeMobile == 'Medium',
          'md:py-[0.375rem]': buttonSizeMobile == 'Small'
        },
        {
          'w-full': fullWidth
        },
        {
          'bg-common-black': buttonVariant == 'Contained',
          'border-common-black': buttonVariant == 'Contained',
          '[&_svg]:fill-common-white': buttonVariant == 'Contained',
          'hover:outline-none': buttonVariant == 'Contained' && !rest.disabled,
          'hover:bg-gray-100': buttonVariant == 'Contained' && !rest.disabled,
          'hover:border-gray-100': buttonVariant == 'Contained' && !rest.disabled,
          'active:outline-none': buttonVariant == 'Contained' && !rest.disabled,
          'active:bg-gray-100': buttonVariant == 'Contained' && !rest.disabled,
          'active:border-gray-100': buttonVariant == 'Contained' && !rest.disabled,
          'bg-gray-100': buttonVariant == 'Contained' && rest.disabled,
          'border-gray-100': buttonVariant == 'Contained' && rest.disabled,
          'text-gray-80': buttonVariant == 'Contained' && rest.disabled
        },
        {
          'text-common-black': buttonVariant == 'Outline',
          'bg-transparent': buttonVariant == 'Outline',
          'border-common-black': buttonVariant == 'Outline',
          '[&_svg]:fill-common-black': buttonVariant == 'Outline',
          '[&_svg]:hover:fill-common-white': buttonVariant == 'Outline' && !rest.disabled,
          '[&_svg]:active:fill-common-white': buttonVariant == 'Outline' && !rest.disabled,
          'hover:outline-none': buttonVariant == 'Outline' && !rest.disabled,
          'hover:bg-common-black': buttonVariant == 'Outline' && !rest.disabled,
          'hover:text-common-white': buttonVariant == 'Outline' && !rest.disabled,
          'active:outline-none': buttonVariant == 'Outline' && !rest.disabled,
          'active:bg-common-black': buttonVariant == 'Outline' && !rest.disabled,
          'active:text-common-white': buttonVariant == 'Outline' && !rest.disabled,
          'focus:bg-blue-50': buttonVariant == 'Outline' && !rest.disabled,
          'bg-gray-80': buttonVariant == 'Outline' && rest.disabled,
          'text-gray-100': buttonVariant == 'Outline' && rest.disabled
        },
        {
          'bg-yellow-100': buttonVariant == 'Yellow',
          'border-yellow-100': buttonVariant == 'Yellow',
          'text-common-black': buttonVariant == 'Yellow',
          'focus:bg-yellow-100': buttonVariant == 'Yellow' && !rest.disabled,
          'focus:border-yellow-100': buttonVariant == 'Yellow' && !rest.disabled,
          'focus:text-common-black': buttonVariant == 'Yellow' && !rest.disabled,
          'hover:bg-yellow-darker': buttonVariant == 'Yellow' && !rest.disabled,
          'hover:border-yellow-darker': buttonVariant == 'Yellow' && !rest.disabled,
          'hover:text-common-black': buttonVariant == 'Yellow' && !rest.disabled,
          'active:outline-none': buttonVariant == 'Yellow' && !rest.disabled,
          'active:bg-yellow-darkest': buttonVariant == 'Yellow' && !rest.disabled,
          'active:border-yellow-darkest': buttonVariant == 'Yellow' && !rest.disabled,
          'border-gray-100': buttonVariant == 'Yellow' && rest.disabled,
          'text-gray-80': buttonVariant == 'Yellow' && rest.disabled,
          'bg-gray-100': buttonVariant == 'Yellow' && rest.disabled
        },
        {
          'bg-purple-normal': buttonVariant == 'Purple',
          'border-purple-normal': buttonVariant == 'Purple',
          'text-common-white': buttonVariant == 'Purple',
          'focus:bg-purple-normal': buttonVariant == 'Purple' && !rest.disabled,
          'focus:border-purple-normal': buttonVariant == 'Purple' && !rest.disabled,
          'focus:text-common-white': buttonVariant == 'Purple' && !rest.disabled,
          'hover:bg-purple-dark': buttonVariant == 'Purple' && !rest.disabled,
          'hover:border-purple-dark': buttonVariant == 'Purple' && !rest.disabled,
          'hover:text-common-white': buttonVariant == 'Purple' && !rest.disabled,
          'active:outline-none': buttonVariant == 'Purple' && !rest.disabled,
          'active:bg-purple-dark': buttonVariant == 'Purple' && !rest.disabled,
          'active:border-purple-dark': buttonVariant == 'Purple' && !rest.disabled
        },
        'flex',
        'items-center',
        'justify-center',
        className
      )}
    >
      {loading && <Oval height="1rem" className={cn('absolute')} />}
      <div aria-hidden={loading} className={cn({ invisible: loading }, 'flex', 'items-center')}>
        {icon && iconPosition === LegacyIconPosition.InFront && <span>{icon}</span>}
        {children}
        {icon && iconPosition === LegacyIconPosition.Behind && <span>{icon}</span>}
      </div>
    </LegacyButtonComponent>
  );
};

export { LegacyButton, LegacyButtonVariant, LegacyButtonSize, LegacyIconPosition };
