import * as React from 'react';
import { cn } from '@/utils/utils';

interface Props {
  currentStep: number;
  numberOfSteps: number;
  radius: number;
}

const StepperCircleMobile: React.FC<Props> = ({ currentStep, numberOfSteps, radius }) => {
  const step = currentStep + 1;
  const circleStrokeDasharray = radius * 0.8 * Math.PI;
  const progress = circleStrokeDasharray - circleStrokeDasharray * (step / numberOfSteps);

  return (
    <div className={cn('relative')} style={{ width: radius + 'px', height: radius + 'px' }}>
      <svg className={cn('[transform:rotate(-90deg)]')} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <circle className={cn('fill-none', 'stroke-gray-40', 'stroke-[20%]')} cx="50%" cy="50%" r="40%"></circle>
        <circle
          className={cn(
            'fill-none',
            'stroke-yellow-100',
            'stroke-[20%]',
            '[transition:_stroke-dasharray_0.7s_linear_0s]'
          )}
          cx="50%"
          cy="50%"
          r="40%"
          style={{ strokeDasharray: circleStrokeDasharray + 'px', strokeDashoffset: progress + 'px' }}
        ></circle>
      </svg>
      <span className={cn('text-gray-100', 'absolute', 'left-2/4', 'top-2/4', '[transform:translate(-50%,-50%)]')}>
        <>
          {step}
          <span className={cn('text-gray-60')}>/</span>
          {numberOfSteps}
        </>
      </span>
    </div>
  );
};

export { StepperCircleMobile };
