import * as React from 'react';
import * as Label from '@radix-ui/react-label';
import { cn } from '@/utils/utils';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { Typography } from '@/components/Typography';

export enum LegacyInputSize {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small'
}

interface Props {
  id: string;
  label?: string;
  helperText?: string;
  required?: boolean;
  showRequiredIndicator?: boolean;
  type: React.HTMLInputTypeAttribute;
  autocomplete?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  name: string;
  fullWidth?: boolean;
  prefix?: React.ReactNode;
  postfix?: React.ReactNode;
  className?: string;
  inputmode?: React.HTMLAttributes<HTMLInputElement>['inputMode'];
  disableNumbers?: boolean;
  inputSize?: LegacyInputSize;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const LegacyInput: React.FC<Props> = ({
  id,
  label,
  helperText,
  required = false,
  showRequiredIndicator = false,
  type,
  autocomplete,
  placeholder,
  disabled = false,
  readOnly = false,
  name,
  fullWidth = false,
  prefix,
  postfix,
  className,
  inputmode,
  disableNumbers = false,
  inputSize,
  onChange
}) => {
  const formContext = useFormContext();
  const formMethods = formContext.register(name);
  const hasError = formContext.formState.errors[name] != undefined;
  const isNonEmpty = formContext.watch(name) && formContext.watch(name).length > 0;

  const [isActiveInside, setIsActiveInside] = useState(false);
  const [didClick, setDidClick] = useState(false);
  const isActive = isActiveInside && didClick;

  const handleFocus = () => {
    setIsActiveInside(true);
  };

  const handleBlur = async (event: any) => {
    setIsActiveInside(false);
    setDidClick(false);
    await formMethods.onBlur(event);
  };

  const onMouseDown = () => {
    setDidClick(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
    setDidClick(true);
    formMethods.onChange(event);
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  };

  return (
    <div
      className={cn('inline-flex', 'flex-col', 'items-start', 'gap-3', {
        'w-full': fullWidth
      })}
    >
      {label && (
        <Typography type="XXS-H" component={Label.Root} className={cn('text-common-black')} htmlFor={id}>
          {label}
          &nbsp;
          {showRequiredIndicator && (
            <Typography component="span" ariaHidden={true} className={cn('text-system-red-100')}>
              *
            </Typography>
          )}
        </Typography>
      )}
      <div
        className={cn(
          className,
          'w-full',
          'flex',
          'items-baseline',
          'gap-3',
          'border-[3px]',
          'border-solid',
          'text-gray-80',
          'fill-gray-80',
          'leading-normal',
          {
            'h-[4.5rem]': inputSize === LegacyInputSize.Large,
            'h-14': inputSize === LegacyInputSize.Medium,
            'h-12': inputSize === LegacyInputSize.Small || inputSize == undefined,
            'p-4': inputSize === LegacyInputSize.Large || LegacyInputSize.Medium,
            'py-2': inputSize === LegacyInputSize.Small || inputSize == undefined,
            'px-4': inputSize === LegacyInputSize.Small || inputSize == undefined,
            'md:py-2': inputSize === undefined,
            'md:px-4': inputSize === undefined,
            'md:h-12': inputSize == undefined
          },
          // normal
          {
            'border-gray-40': !disabled,
            'bg-common-white': !disabled,
            'hover(:not focus):border-gray-60': !disabled && !isActive
          },
          // active
          {
            'active:border-common-black': !disabled,
            'active:text-common-black': !disabled,
            'border-common-black': !disabled && isActive,
            'text-common-black': !disabled && isActive
          },
          // focus
          {
            'focus:border-system-focus': !disabled && !isActive,
            'focus-within:border-system-focus': !disabled && !isActive,
            'focus:bg-gray-10': !disabled && !isActive,
            'focus-within:bg-gray-10': !disabled && !isActive
          },
          // disabled
          {
            'border-gray-20': disabled,
            'bg-gray-20': disabled
          },
          // Error
          {
            'border-system-red-100': hasError,
            'bg-common-white': hasError
          },
          // Success
          {
            'border-green-100': isNonEmpty && !hasError && !isActive,
            'text-common-black': isNonEmpty && !hasError && !isActive,
            'fill-gray-100': isNonEmpty && !hasError && !isActive
          }
        )}
      >
        {prefix}
        <input
          type={type}
          autoComplete={autocomplete}
          inputMode={inputmode}
          id={id}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          {...formMethods}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onMouseDown={onMouseDown}
          onChange={handleChange}
          onWheel={disableNumbers ? e => e.currentTarget.blur() : undefined}
          onKeyDown={disableNumbers ? onKeyPress : undefined}
          className={cn(
            'border-0',
            'outline-none',
            'bg-transparent',
            'w-full',
            'h-full',
            'text-[1.125rem]',
            'leading-[130%]'
          )}
        />
        {postfix}
      </div>
      {helperText && (
        <Typography type="XS-B" component={Label.Root} className={cn({ 'text-system-red-100': hasError })} htmlFor={id}>
          {helperText}
        </Typography>
      )}
    </div>
  );
};

export { LegacyInput };
