'use client';

import * as React from 'react';
import { useEffect, useState } from 'react';
import { cn } from '@/utils/utils';
import tailwindConfig from '@/tailwind.config';
import { useMediaQuery } from 'react-responsive';
import Head from 'next/head';
import { DesktopHeader, DesktopHeaderVariant } from '@/components/header/DesktopHeader';
import { MobileHeader, MobileHeaderVariant } from '@/components/header/MobileHeader';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useTranslation } from '@/utils/translation/useTranslation';

// Having this outer Layout and InnerLayout is needed to stop flickering without getting hydration error
const GuestLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { screens } = tailwindConfig.theme;
  const [isSmall, setIsSmall] = useState<boolean>();
  const isSmallQuery = useMediaQuery({
    query: `only screen and (max-width: ${screens.md.max})`
  });

  // This is needed as if we use isSmallQuery directly in the code then NextJS bitches about hydration error
  useEffect(() => {
    setIsSmall(isSmallQuery);
  }, [isSmallQuery]);

  if (isSmall == undefined) {
    return <></>;
  }

  return <InnerLayout page={children} isSmall={isSmall} />;
};

export interface GuestProps {
  isSmall: boolean;
}

interface InnerLayoutProps {
  isSmall: boolean;
  page: React.ReactNode;
}

const InnerLayout: React.FC<InnerLayoutProps> = ({ page, isSmall }) => {
  const { t } = useTranslation();

  const { colors } = tailwindConfig.theme;
  const rawBackgroundColor = colors.gray['10'];
  const backgroundColor = 'bg-gray-10';

  return (
    <>
      <Head>
        <title>{t('guest:title')}</title>
        <meta name="theme-color" content={rawBackgroundColor} />
      </Head>
      <div className={cn('flex', 'flex-col', 'min-h-screen', backgroundColor)}>
        <div>
          <DesktopHeader
            variant={DesktopHeaderVariant.Transparent}
            guest
            showLoginButton={false}
            className={cn({ hidden: isSmall })}
          />
          <MobileHeader variant={MobileHeaderVariant.Transparent} guest className={cn({ hidden: !isSmall })} />
        </div>
        <main
          className={cn(
            'w-full',
            'justify-center',
            'flex',
            'pt-4',
            'px-4',
            'min-h-[calc(100vh-5.5rem)]',
            'md:min-h-[calc(100vh-4.5rem)]',
            'md:pt-6',
            'md:px-6'
          )}
        >
          {!React.isValidElement(page)
            ? page
            : React.cloneElement(page as React.ReactElement<GuestProps>, { isSmall: isSmall })}
        </main>
      </div>
    </>
  );
};

const getLayout = (page: React.ReactNode, queryClient: QueryClient) => {
  return (
    <QueryClientProvider client={queryClient}>
      <GuestLayout>{page}</GuestLayout>
    </QueryClientProvider>
  );
};

export { GuestLayout, getLayout };
