import React from 'react';
import { cn } from '@/utils/utils';
import Chevron from '@/public/assets/icons/chevron.svg';
import { Typography } from '@/components/Typography';
import { ParsedUrlQueryInput } from 'querystring';
import { useRouter } from '@/utils/hooks/useRouter';

export type ArrayOneOrMore<T> = {
  0: T;
} & Array<T>;

export type RowType = {
  text: string;
  href: string;
  isExternalHref?: boolean;
  queryParameters?: ParsedUrlQueryInput;
  dontShowChevron?: boolean;
  rowColor?: string;
};

interface Props {
  rows: ArrayOneOrMore<RowType>;
}

const ActionRows: React.FC<Props> = ({ rows }) => {
  const router = useRouter();

  return (
    <div className={cn('flex', 'flex-col', 'gap-2')}>
      {rows.map((row, index, rows) => {
        const onClick = () => {
          if (row.isExternalHref) {
            let url = row.href;

            if (row.queryParameters && Object.entries(row.queryParameters).length > 0) {
              url += Object.entries(row.queryParameters).reduce(
                (previousValue, currentValue, currentIndex) =>
                  `${previousValue}${currentIndex != 0 ? '&' : ''}${currentValue[0]}=${currentValue[1]}`,
                '?'
              );
            }

            window.location.href = url;
          } else {
            router.push(row.href, { query: row.queryParameters });
          }
        };

        return (
          <div
            key={`action-rows-row-${index}`}
            className={cn(
              'rounded-lg',
              'px-6',
              'py-3',
              'bg-common-white',
              'flex',
              'justify-between',
              'items-center',
              'cursor-pointer',
              row.rowColor ?? 'bg-gray-20',
              'hover:bg-gradient',
              'hover:from-opacity-black-8',
              'hover:to-opacity-black-8'
            )}
            onClick={onClick}
          >
            <Typography component="p" type="XS-B">
              {row.text}
            </Typography>
            {!row.dontShowChevron && <Chevron width="1.5rem" height="1.5rem" className={cn('fill-common-black')} />}
          </div>
        );
      })}
    </div>
  );
};

export { ActionRows };
